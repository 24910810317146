<template>
  <div id="whyneuroLeap" class="whyNeuroLeap">
    <h1>{{ $t("whyNeuroLeap.heading") }}</h1>
    <img
      src="../img/3rdOnLandingPage.jpeg"
      alt="happy_hero"
      style="
        width: 60%;
        height: auto;
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 5%;
      "
    />
    <p>{{ $t("whyNeuroLeap.text1") }}</p>
    <p>{{ $t("whyNeuroLeap.text2") }}</p>
    <p>{{ $t("whyNeuroLeap.text3") }}</p>

    <button class="collapsible">
      {{ $t("whyNeuroLeap.collapse_1_title") }}
    </button>
    <div class="collapsible_content">
      <p>{{ $t("whyNeuroLeap.collapse_1_content") }}</p>
    </div>

    <button class="collapsible">
      {{ $t("whyNeuroLeap.collapse_2_title") }}
    </button>
    <div class="collapsible_content">
      <p>{{ $t("whyNeuroLeap.collapse_2_content") }}</p>
    </div>

    <button class="collapsible">
      {{ $t("whyNeuroLeap.collapse_3_title") }}
    </button>
    <div class="collapsible_content">
      <p>{{ $t("whyNeuroLeap.collapse_3_content") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  // global function to make collapsible effect
  // gives every class called "collapsible" the actual collapsible effect for the next element
  mounted: function () {
    // For Donation
    let externalScript = document.createElement("script");
    externalScript.setAttribute(
      "src",
      "https://www.paypal.com/sdk/js?client-id=Acm_FNoZJuwPQTLbA-0NqylATBlhwanM71KkAGjAb9ryO_yrzsNv5NZgeg2XdHE1Y9IRGasT2KpmLhZm&enable-funding=venmo&currency=USD"
    );
    document.head.appendChild(externalScript);
    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.maxHeight) {
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = content.scrollHeight + "px";
        }
      });
    }
  },
};
</script>

<style scoped>
#whyneuroLeap:target {
  padding-top: 7vh;
}

@media screen and (max-width: 1397px) and (min-width: 860px) {
  #whyneuroLeap:target {
    padding-top: 15vh;
  }
}
</style>
