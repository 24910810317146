<template>
  <!-- main nav container -->
  <div class="navbar" id="nav">
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
    />

    <!-- nav container left: logo -->
    <div class="nav-logo" id="myNav-logo">
      <!-- logo -->
      <a href="#">
        <img
          src="../img/Logo1.png"
          alt="logo1"
          width="60px"
          v-on:click="
            hideNavResponsive();
            routeToHome();
          "
        />
        <span
          style="
            color: black;
            font-size: xxx-large;
            position: relative;
            bottom: -13px;
          "
          >NeuroLeap</span
        >
      </a>

      <!-- link for burger-icon (mobile nav): with css only displayed when mobile screen, on click do function navResponsive() -->
      <a href="javascript:void(0);" class="icon" v-on:click="navResponsive()">
        <i class="fa fa-bars"></i>
      </a>
    </div>

    <!-- nav container mid: links -->
    <!-- if NOT field trial, have these buttons -->
    <div
      v-if="$route.name != 'fieldTrial'"
      class="nav-links"
      id="myNav-links"
      v-on:click="hideNavResponsive()"
    >
      <a @click="routeToHome()" href="#problems">{{ $t("Header[1]") }}</a>
      <a @click="routeToHome()" href="#products">{{ $t("Header[2]") }}</a>
      <a @click="routeToHome()" href="#research">{{ $t("Header[3]") }}</a>
      <a @click="routeToHome()" href="#whyneuroLeap">{{ $t("Header[4]") }}</a>
      <a @click="routeToHome()" href="#about">{{ $t("Header[0]") }}</a>
      <a @click="routeToHome()" href="#faq">{{ $t("Header[5]") }}</a>
      <a @click="routeToHome()" href="#contactus">{{ $t("Header[7]") }}</a>
      <router-link @click="routeToHome()" :to="{ path: '/partner' }">{{
        $t("Header[10]")
      }}</router-link>
      <router-link @click="routeToHome()" :to="{ path: '/events' }">{{
        $t("Header[11]")
      }}</router-link>
      <router-link
        v-if="loggedInAdmin"
        @click="routeToHome()"
        :to="{ path: '/userManagement' }"
      >
        UserManagement
      </router-link>
      <router-link
        v-if="loggedInParticipant"
        @click="routeToHome()"
        :to="{ path: '/registeredEvents' }"
      >
        Registered Events
      </router-link>
    </div>

    <!-- else, have these buttons -->
    <div
      v-else
      class="nav-links"
      id="myNav-links"
      v-on:click="hideNavResponsive()"
    >
      <a href="#about">{{ $t("Header[0]") }}</a>
      <a href="#questionnaireform">{{ $t("Header[13]") }}</a>
      <a href="#fieldTrialVideo">{{ $t("Header[14]") }}</a>
      <a href="#fieldTrialInfo">{{ $t("Header[15]") }}</a>

      <a href="#fieldtrialform">{{ $t("Header[16]") }}</a>
    </div>

    <!-- nav container right: buttons -->
    <div class="nav-btns" id="myNav-btns">
      <!-- supportUs button -->
      <router-link to="/supportUs"
        ><button class="nav-btn btn-donate" @click="hideNavAndScroll()">
          {{ $t("Header[8]") }}
        </button></router-link
      >
      <!-- fieldtrial button -->
      <router-link to="/fieldTrial"
        ><button class="nav-btn btn-field" @click="hideNavAndScroll()">
          {{ $t("Header[12]") }}
        </button></router-link
      >
      <!-- if NOT logged in -->
      <span v-if="!loggedIn">
        <router-link to="/login">
          <!-- Original Value: {{ $t("Header[8]") }} -->
          <button class="nav-btn btn-login">
            {{ $t("Header[9]") }}
          </button>
        </router-link>
      </span>

      <!-- if logged in -->
      <span v-else>
        <!-- Original Value: {{ $t("Header[8]") }} -->
        <button @click="logout" class="nav-btn btn-login">Logout</button>
      </span>

      <!-- language buttons, shows all languages in /locales folder as options -->
      <select
        v-model="$i18n.locale"
        class="nav-btn btn-language"
        v-on:change="hideNavResponsive()"
      >
        <option
          v-for="(lang, i) in $i18n.availableLocales"
          :key="`Lang${i}`"
          :value="lang"
          class="btn-option"
        >
          {{ lang }}
          <span v-if="i == 0">/ عربي</span>
          <span v-else-if="i == 1">/ 中文简体</span>
          <span v-else-if="i == 2">/ 中文繁体</span>
          <span v-else-if="i == 3"></span>
          <span v-else-if="i == 4">/ Français</span>
          <span v-else-if="i == 5">/ Deutsch</span>
          <span v-else-if="i == 6">/ 日本語</span>
          <span v-else-if="i == 7">/ 한국인</span>
          <span v-else-if="i == 8">/ Português</span>
          <span v-else-if="i == 9">/ русский</span>
          <span v-else-if="i == 10">/ Española</span>
          <span v-else-if="i == 11">/ Svenska</span>
          <span v-else-if="i == 12">/ Tiếng Việt</span>
        </option>
      </select>

      <!-- accessibility icons -->
      <div class="nav-access dropdown" id="myNav-access">
        <a href="javascript:void(0);" v-on:click="toggleAccessibility()">
          <img
            src="../img/accessibilityicons/access_png.png"
            alt="access"
            class="dropbtn"
            width="50px"
          />
        </a>
        <br />
        <div class="dropdown_content" id="myDropdown">
          <a href="javascript:void(0);" v-on:click="toggleContent('contrast')">
            <img
              src="../img/accessibilityicons/contrast_png.png"
              alt="contrast"
              class="dropbtn"
              width="40px"
            />
          </a>
          <div
            class="dropdown_content_contrast nowrap"
            id="myDropdown_contrast"
          >
            <button
              v-on:click="
                setColor('BW');
                changeColor('BW');
              "
            >
              B&W
            </button>
            <button
              v-on:click="
                setColor('BY');
                changeColor('BY');
              "
            >
              B&Y
            </button>
            <button
              v-on:click="
                setColor('WB');
                changeColor('WB');
              "
            >
              W&B
            </button>
          </div>

          <a
            href="javascript:void(0);"
            v-on:click="toggleContent('read')"
            id="access_read"
          >
            <img
              src="../img/accessibilityicons/Easy Read_png.png"
              alt="easy_read"
              class="dropbtn"
              width="40px"
            />
          </a>
          <div class="dropdown_content_read nowrap" id="myDropdown_read">
            To be update
          </div>

          <a
            href="javascript:void(0);"
            v-on:click="toggleContent('hear')"
            id="access_hear"
          >
            <img
              src="../img/accessibilityicons/hear_png.png"
              alt="hear"
              class="dropbtn"
              width="40px"
            />
          </a>
          <div class="dropdown_content_hear nowrap" id="myDropdown_hear">
            To be update
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../bus";

// when click on places other than access icons, access content will be cleared
window.onclick = function (event) {
  if (!event.target.matches(".dropbtn")) {
    var dropdowns_contrast = document.getElementsByClassName(
      "dropdown_content_contrast"
    );
    var dropdowns_read = document.getElementsByClassName(
      "dropdown_content_read"
    );
    var dropdowns_hear = document.getElementsByClassName(
      "dropdown_content_hear"
    );
    var dropdowns_language = document.getElementsByClassName(
      "dropdown_content_language"
    );
    var dropdowns = document.getElementsByClassName("dropdown_content");

    for (var i = 0; i < dropdowns_contrast.length; i++) {
      if (dropdowns_contrast[i].classList.contains("show")) {
        dropdowns_contrast[i].classList.remove("show");
      }
    }
    for (i = 0; i < dropdowns_read.length; i++) {
      if (dropdowns_read[i].classList.contains("show")) {
        dropdowns_read[i].classList.remove("show");
      }
    }
    for (i = 0; i < dropdowns_hear.length; i++) {
      if (dropdowns_hear[i].classList.contains("show")) {
        dropdowns_hear[i].classList.remove("show");
      }
    }
    for (i = 0; i < dropdowns_language.length; i++) {
      if (dropdowns_language[i].classList.contains("show")) {
        dropdowns_language[i].classList.remove("show");
      }
    }
    for (i = 0; i < dropdowns.length; i++) {
      if (dropdowns[i].classList.contains("show")) {
        dropdowns[i].classList.remove("show");
      }
    }
  }
};
export default {
  //props: ['username'],
  data() {
    return {
      color: "BW",
    };
  },
  props: {
    username: {
      type: String,
      default: "username",
    },
  },

  computed: {
    loggedIn() {
      var logged = this.$store.getters.isLoggedIn;
      return logged;
      // return true;
    },
    loggedInAdmin() {
      var logged = this.$store.getters.isLoggedIn;
      var role = this.$store.getters.getUserLevel;
      return logged && role == "Admin";
      // return true;
    },
    loggedInParticipant() {
      var event = this.$store.getters.getEventID;
      return event != null;
      // return true;
    },
  },
  // want to preload images because first click to expand accessbility icons, there will be some time delay
  // mounted() {
  //   var images = new Array();
  //   function preload() {
  //     var srcs = [
  //       "../img/accessibilityicons/contrast_png.png",
  //       "../img/accessibilityicons/Easy Read_png.png",
  //       "../img/accessibilityicons/hear_png.png",
  //       "../img/accessibilityicons/Language_png.png",
  //     ];
  //     for (var i = 0; i < srcs.length; i++) {
  //       images[i] = new Image();
  //       images[i].src = srcs[i];
  //     }
  //   }
  //   preload();
  // },
  watch: {
    $route() {
      // console.log(this.color);
      // console.log(this.$route.path);
      this.setColor(this.color);
      this.changeColor(this.color);
    },
  },
  methods: {
    logout() {
      this.$store.commit("setAuthUser", null);
      this.$store.commit("setAuthUser", null);
      this.$store.commit("setEventID", null);
      this.$store.commit("setUserLevel", null);

      if (this.$route.name != "App") {
        this.$router.push("/");
      }
    },
    // when burger-icon of mobile nav is clicked, add ".responsive" to logo, links and button classes
    // allows to style elements differently when mobile screen
    navResponsive: function () {
      // nav-logo
      var x = document.getElementById("myNav-logo");
      if (x.className === "nav-logo") {
        x.className += " responsive";
      } else {
        x.className = "nav-logo";
      }
      // nav-links
      var y = document.getElementById("myNav-links");
      if (y.className === "nav-links") {
        y.className += " responsive";
      } else {
        y.className = "nav-links";
      }
      // nav-buttons
      var z = document.getElementById("myNav-btns");
      if (z.className === "nav-btns") {
        z.className += " responsive";
      } else {
        z.className = "nav-btns";
      }

      // nav-access
      var access = document.getElementById("myNav-access");
      if (access.className === "nav-access dropdown") {
        access.className += " responsive";
      } else {
        access.className = "nav-access dropdown";
      }
    },
    // hide mobile nav when nav-element has been clicked (hide content / elements, show only navbar)
    hideNavResponsive: function () {
      // nav-logo
      var x = document.getElementById("myNav-logo");
      x.className = "nav-logo";
      // nav-links
      var y = document.getElementById("myNav-links");
      y.className = "nav-links";
      // nav-buttons
      var z = document.getElementById("myNav-btns");
      z.className = "nav-btns";
      // nav-access
      var access = document.getElementById("myNav-access");
      access.className = "nav-access dropdown";
      // emit nav-change to global EventBus, will be caught by Slider and slide content will be updated (for language change)
      //EventBus.$emit('nav-change');
    },
    routeToHome: function () {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },

    hideNavAndScroll: function () {
      this.hideNavResponsive();
      window.scrollTo(0, 0);
    },
    comboFunction: function () {
      this.hideNavResponsive();
      this.routeToHome();
    },
    toggleAccessibility: function () {
      document.getElementById("myDropdown").classList.toggle("show");
    },
    toggleContent: function (type) {
      if (type == "contrast") {
        document.getElementById("myDropdown_contrast").classList.toggle("show");
        this.clearOther(type);
      } else if (type == "read") {
        document.getElementById("myDropdown_read").classList.toggle("show");
        this.clearOther(type);
      } else if (type == "hear") {
        document.getElementById("myDropdown_hear").classList.toggle("show");
        this.clearOther(type);
      } else if (type == "language") {
        document.getElementById("myDropdown_language").classList.toggle("show");
        this.clearOther(type);
      } else {
        return;
      }
    },
    clearOther: function (type) {
      // types must matches ids
      var types = ["contrast", "read", "hear"];
      var ids = ["myDropdown_contrast", "myDropdown_read", "myDropdown_hear"];
      for (var i = 0; i < types.length; i++) {
        if (types[i] == type) {
          continue;
        } else {
          document.getElementById(ids[i]).classList.toggle("show", false);
        }
      }
    },
    setColor(type) {
      this.color = type;
    },

    changeColor: function (type) {
      var FG, BG;
      if (type == "BW") {
        FG = "black";
        BG = "white";
      } else if (type == "BY") {
        FG = "black";
        BG = "yellow";
      } else if (type == "WB") {
        FG = "white";
        BG = "black";
      }
      // background
      document.body.style.backgroundColor = BG;
      // foreground
      document.body.style.color = FG;

      this.extraColorChange(type);
    },
    extraColorChange: function (type) {
      var txtColor, bgColor;
      if (type == "WB") {
        txtColor = "white";
        bgColor = "black";
      } else if (type == "BW") {
        txtColor = "black";
        bgColor = "white";
      } else {
        txtColor = "black";
        bgColor = "yellow";
      }

      if (this.$route.path == "/") {
        // change about us txt bgcolor
        document.getElementById("about-text").style.backgroundColor = bgColor;

        // change problems txt color
        var problems = document.getElementsByClassName("txt-black");
        for (var i = 0; i < problems.length; i++) {
          problems[i].style.color = txtColor;
        }

        // change partner side color
        document.getElementById("partner").style.backgroundColor = bgColor;

        // contactus
        document.getElementById("container_contact").style.backgroundColor =
          bgColor;
        var media = document.getElementsByClassName("media_name");
        for (var j = 0; j < media.length; j++) {
          media[j].style.color = txtColor;
        }

        // media
        var media_icon = document.getElementsByClassName("icon_socialmedia");
        for (var m = 0; m < media_icon.length; m++) {
          media_icon[m].style.backgroundColor = "white";
        }

        // footer
        var footer = document.getElementsByClassName("footer-list-link");
        for (var k = 0; k < footer.length; k++) {
          footer[k].style.color = txtColor;
        }

        // products
        document.getElementById("vueperslides").style.backgroundColor = "white";
        var collapsible = document.getElementsByClassName("card");
        for (var l = 0; l < collapsible.length; l++) {
          collapsible[l].style.backgroundColor = bgColor;
        }
      } else if (this.$route.path == "/fieldTrial") {
        // change about us txt bgcolor
        document.getElementById("about-text").style.backgroundColor = bgColor;
        // footer
        footer = document.getElementsByClassName("footer-list-link");
        for (k = 0; k < footer.length; k++) {
          footer[k].style.color = txtColor;
        }
        // fieldtrail questionnaire
        document.getElementById("fieldtrail_questions").style.color = txtColor;
      }

      // console.log(this.color);
    },
  },
  // hide mobile nav when nav-element has been clicked (hide content / elements, show only navbar)
  hideNavResponsive: function () {
    // nav-logo
    var x = document.getElementById("myNav-logo");
    x.className = "nav-logo";
    // nav-links
    var y = document.getElementById("myNav-links");
    y.className = "nav-links";
    // nav-buttons
    var z = document.getElementById("myNav-btns");
    z.className = "nav-btns";
    // nav-access
    var access = document.getElementById("myNav-access");
    access.className = "nav-access dropdown";
    // emit nav-change to global EventBus, will be caught by Slider and slide content will be updated (for language change)
    EventBus.$emit("nav-change");
  },
};
</script>

<style scoped>
/* MAIN CONTAINERS */
/* | nav-logo |    | nav-links |    | nav-btns | */
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
  position: fixed;
  z-index: 10;
  text-align: center;
  border: 1px solid #dcdfe6;
}

.nav-logo {
  width: auto;
  float: left;
  display: block;
}

.nav-links {
  display: inline-block;
  margin: 0 auto;
  padding-top: 8px;
  text-decoration-color: #bd4594;
}

.nav-btns {
  float: right;
}

.nav-access {
  float: right;
}

/* Elements inside the containers */
.nav-links a {
  color: black;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 8px;
  padding-top: 8px;
  text-decoration: none;
  font-size: 17px;
}

.nav-btn {
  margin-right: 10px;
  margin-top: 5px;
  padding: 7px 8px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

/* Button classes */
.btn-login {
  color: white;
  background-color: #f4892f;
}

.btn-donate {
  color: white;
  background-color: #f4892f;
}

.btn-field {
  color: white;
  background-color: #f4892f;
}

.btn-language {
  color: white;
  background-color: grey;
}

.btn-option {
  background-color: grey;
  color: white;
}

.dropdown_content {
  display: none;
  position: absolute;
  background-color: white;
}

.dropdown_content_contrast {
  display: none;
  position: absolute;
  border-left: 3px solid black;
  border-right: 3px solid black;
  left: -160px;
  top: 7px;
}

.dropdown_content_read {
  display: none;
  position: absolute;
  border-left: 3px solid black;
  border-right: 3px solid black;
  left: -130px;
  top: 50px;
}

.dropdown_content_hear {
  display: none;
  position: absolute;
  border-left: 3px solid black;
  border-right: 3px solid black;
  left: -130px;
  top: 90px;
}

.dropdown_content_language {
  display: none;
  position: absolute;
  border-left: 3px solid black;
  border-right: 3px solid black;
  left: -130px;
  top: 130px;
}

/* keep content at same line */
.nowrap {
  white-space: nowrap;
}

/* make toggle work */
.show {
  display: block;
}

/* Change the color of links on hover */
.nav-links a:hover {
  background-color: #ddd;
  color: black;
}

/* Hide the link (burger icon) of mobile nav when it is not a small screen */
.nav-logo .icon {
  display: none;
}

/* Make nav elements a bit smaller after 1080 px to not make it overlap, provides still normal nav on most tablets */
@media screen and (max-width: 1080px) {
  .nav-links a {
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .nav-btns .nav-btn {
    font-size: 13px;
    padding: 10px;
    margin-top: 10px;
  }
}

/* When the screen is less than 860 pixels wide, hide all links and buttons. Show the link (burger icon) that should be used as mobile navigation */
@media screen and (max-width: 860px) {
  .nav-links {
    display: none;
  }

  .nav-btns {
    display: none;
  }

  .nav-access {
    display: none;
  }

  .nav-logo {
    display: block;
  }

  .nav-logo a.icon {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 14px;
    padding-top: 16px;
    color: grey;
  }
}

/* The "responsive" class is added to the nav-elements with JavaScript function "navResponsive() when the user clicks on the burger icon. These classes make the elements display vertically instead of horizontally on small screens makes the nav-links look good on small screens */
@media screen and (max-width: 860px) {
  .nav-links.responsive {
    position: relative;
    display: block;
  }

  .nav-logo.responsive a.icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .nav-links.responsive a {
    float: none;
    display: block;
    text-align: left;
    border-bottom: 1px solid #dcdfe6;
  }

  .nav-logo.responsive {
    float: none;
    display: block;
    text-align: left;
    border-bottom: 1px solid #dcdfe6;
  }

  .nav-btns.responsive {
    float: none;
    display: block;
    text-align: left;
  }

  .nav-btns.responsive .nav-btn {
    float: left;
    text-align: left;
    display: block;
    padding: 14px 16px;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .nav-links {
    padding-top: 0px;
  }

  .nav-access.responsive {
    position: absolute;
    top: 10px;
    right: 30px;
    float: none;
    text-align: left;
    /* float: left; */
    display: block;
  }
}
</style>
