<template>
  <div class="about-and-mission" id="about">
    <div v-if="$route.name != 'fieldTrial'" class="mission-complete">
      <div class="mission-aside"></div>
      <div class="mission-text">
        <img
          class="mission-picture"
          src="../img/2ndOnLandingPage.jpeg"
          alt="happy_hero"
          style="border-radius: 5%; width: 60%; float: left; margin-right: 15px"
        />
        <br />
        <br />

        <p style="font-size: xx-large" strong class="mission-headline">
          {{ $t("About.Mission") }}
        </p>
        <div class="mission-body">{{ $t("About.MContent") }}</div>
        <div v-if="logged" class="mission-button">
          <a href="https://youtu.be/8fxVQeGII8s" target="_blank">
            <el-button round>{{ $t("About.Button") }}</el-button>
          </a>
        </div>

        <div v-else class="mission-button">
          <el-button round>{{ $t("About.login") }}</el-button>
        </div>
      </div>
    </div>

    <div class="about-around">
      <div class="about">
        <!-- left bg -->
        <div class="background1">
          <img
            class="bubbles"
            background-image
            src="../img/About_content_Graph1.png"
            alt="about-left"
          />
        </div>
        <!-- text -->
        <div class="about-text" id="about-text">
          <div class="line-about">
            <h1 class="headline-about">{{ $t("About.About") }}</h1>
            <div class="body-about" v-html="$t('About.AContent')"></div>
          </div>
        </div>
        <!-- right bg -->
        <div class="background2">
          <img
            class="bubbles"
            background-image
            src="../img/About_content_Graph2.png"
            alt="about_right"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    logged() {
      var logged = this.$store.getters.isLoggedIn;
      // console.log(logged);

      return logged;
    },
  },
};
</script>

<style scoped>
#about:target {
  padding-top: 7vh;
}

@media screen and (max-width: 1397px) and (min-width: 860px) {
  #about:target {
    padding-top: 15vh;
  }
}

.about-around {
  width: 100%;
  /* margin-top: 20px; */
  margin-bottom: 5%;
}

.about {
  /* vertical-align: top; */
  position: relative;
  overflow: visible;
  /* min-height: 18.75rem; */
}

.background1 {
  position: relative;
  top: 100px;
  width: 30%;
  overflow-x: hidden;
  z-index: 1;
}

.background2 {
  position: relative;
  width: 25%;
  margin-left: 75%;
  bottom: 300px;
  /* margin-bottom: -450px; */
  /* bottom: -50%; */
  overflow-x: hidden;
  overflow-y: visible;
  z-index: 3;
}

.about-text {
  position: relative;
  margin-top: -12%;
  margin-left: 12%;
  /* margin-right: 15%; */
  width: 75%;
  border: 1px solid #dcdfe6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow-x: hidden;
  z-index: 2;
  background-color: white;
  min-height: 100px;
}

.line-about {
  margin-top: 15%;
  margin-bottom: 10%;
  margin-left: 10%;
  margin-right: 12%;
}

.bubbles {
  width: 100%;
}

.headline-about {
  text-align: center;
  margin: 0px;
}

.body-about {
  text-align: center;
  padding: 20px;
}

.mission-complete {
  /* margin-top: 15%; */
  width: 100%;
  height: auto;
  padding-right: 10%;
}

.mission-aside {
  float: left;
  width: 30%;
  height: auto;
}

.mission-picture {
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.mission-text {
  width: 100%;
  float: left;
  clear: right;
  height: auto;
  position: relative;
}

.mission-headline {
  width: 100%;
  margin-left: 5%;
  margin-top: 0%;
}

.mission-body {
  width: 100%;
  margin-left: 5%;
  font-size: 1.25rem;
}

.mission-button {
  margin-left: 5%;
  bottom: 0%;
  margin-top: 5%;
}

@media screen and (max-width: 600px) {
  .about-text {
    min-height: 260px;
  }

  .mission-aside {
    width: 90%;
    height: auto;
  }

  .mission-text {
    width: 100%;
    clear: both;
    height: auto;
  }

  .mission-headline {
    width: 100%;
    margin-top: 5%;
  }

  .mission-body {
    width: 100%;
  }
}
</style>
